.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.cursor {
  cursor: pointer;
}

/* react calendar */
.react-calendar__tile--active {
  background-color: white !important;
}
.react-calendar__month-view__days__day abbr {
  
  height: 42px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50% !important;
  width:42px !important;
  line-height: normal !important;
}

.highlight_button abbr {
  color: white;
  /* font-weight: bold; */
  border: none;
  background-color: #f15a22 !important;
  font-size: 20px;
}

.react-calendar {
  width: 100% !important;
  max-width: 100% !important;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  /* margin: 0 !important; */
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
  justify-content: space-around;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
  max-width: 45px !important;
  height: 45px !important;
  /* margin-right: 24px !important;
  margin-left: 23px !important; */
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  color: #757575 !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus{
  background-color: white !important;
}

.react-calendar__tile:enabled:hover abbr,
.react-calendar__tile:enabled:focus abbr{
  background-color: #e6e6e6;
  /* font-weight: bold; */
  border: none;
  border-radius: 50%;
  max-height: 45px !important;
}

.react-calendar__tile--now {
  /* background: #ffa176 !important; */
  background-color: white !important;
  color: white !important;
  /* font-weight: bold; */
  border: none;
  border-radius: 50%;
  font-size: 20px;
  max-height: 45px !important;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffa176;
  color: white !important;
  font-weight: bold;
  border: none;
  border-radius: 50%;
  font-size: 20px;
  max-height: 45px !important;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active abbr{
  background: #1087ff !important;
  /* font-weight: bold; */
  border: none;
  border-radius: 50%;
  font-size: 20px;
  max-height: 45px !important;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: red;
}

/* input[type="date"]:before {
  color: lightgrey;
  content: attr(placeholder) !important;
  margin-right: 0.5em;
}
input[type="date"]:focus:before {
  content: "" !important;
}
input[type="time"]:before {
  color: lightgrey;
  content: attr(placeholder) !important;
  margin-right: 0.5em;
}
input[type="time"]:focus:before {
  content: "" !important;
} */

.bordern {
  border: none;
}
.highcharts-credits {
  display: none;
}
tspan {
  display: none;
}

.react-calendar__month-view__days {
  justify-content: space-around;
}
.react-calendar__month-view__days__day {
  width: 14.28%;
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex: 0 0 10.2857% !important;  */
  /* max-width: 45px !important; */
  height: 50px !important;
  /* margin-right: 24px !important;
  margin-left: 23px !important; */
  /* background-color: red !important; */
}
abbr[title] {
  text-decoration: none !important;
}
.react-calendar__navigation button {
  font-size: 22px !important;
  font-weight: bold !important;
}
.react-calendar__year-view__months__month {
  max-width: 100px !important;
  min-height: 62px !important;
  /* margin-right: 24px !important;
  margin-left: 37px !important; */
  /* background-color: red !important; */
}
/* .react-calendar__year-view__months__month:hover {
  background-color: green !important;
  max-width: 100px !important;
  max-height: 90px !important;
} */

abbr[aria-label] :hover {
  background-color: yellow;
}
.react-calendar__year-view .react-calendar__tile, .react-calendar__decade-view .react-calendar__tile, .react-calendar__century-view .react-calendar__tile {
  padding: 1em 0.5em  !important;
} 
.react-calendar__tile.react-calendar__decade-view__years__year{
  max-width: 65px !important;
  min-height: 62px !important;
  margin-right: 24px !important;
  margin-left: 37px !important;
}
.react-calendar__tile--hasActive{
  background-color: #a0a096 !important;
}

.react-calendar__year-view__months__month.react-calendar__tile--now {
  background-color: #F15A22 !important;
}
.react-calendar__year-view__months__month:enabled:hover,
.react-calendar__year-view__months__month:enabled:focus  {
  background-color: #1087ff !important;
  color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.react-calendar__year-view__months__month:hover abbr{
  background-color: unset !important;
}


.react-calendar__decade-view__years__year.react-calendar__tile--now {
  background-color: #F15A22 !important;
}
.react-calendar__decade-view__years__year:enabled:hover,
.react-calendar__decade-view__years__year:enabled:focus  {
  background-color: #1087ff !important;
  color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.react-calendar__decade-view__years__year:hover abbr{
  background-color: unset !important;
}
/* .bg_body {
  background-color: #f3f3f6;
} */
/* .event-details-invited1 .teb_text span{
  display: none;
} */
.IUybi {
  background-color: #F7F7F8 !important;
}

@media (max-width: 1199.98px) {
  .leaderBoardFilterBtn {
    position: static !important;
    transform: none !important;
    margin-bottom: 8px;
    display: flex;
  }
  .leaderBoardFilterBtn button {
    margin-left: auto;
  } 
}
@media (min-width: 280px) and (max-width: 376px) {
  .leaderBoardFilterBtn {
    transform: none !important;
  }
}
.jQaZyJ{
justify-content: start !important;
}
.hRTNWJ{
justify-content: start !important;
}
.swal-button--danger{
  background-color: #F15A22;
}