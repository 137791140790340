.navSearchBarUl{
    padding: 20px !important;
    border-radius: 15px !important;
    /* box-shadow: none !important; */
    box-shadow: 0px 4px 136px -11px rgba(0, 0, 0, 0.10) !important;


}

.navPillsSearch .nav-link{
    color: #121016 !important;
    text-align: center !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
}
.navPillsSearch .active .nav-link{
    color: #EF5036 !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    position: relative !important;
   
}

.navPillsSearch .active .nav-link::after{
    position: absolute;
    content: '';
    left: 0;
    bottom: -1px;
    background-color: #EF5036;
    width: 100%;
    height: 1.5px;

}
.userDiv{
    overflow: hidden !important;
margin-bottom:18px !important ;
color: #121016 !important;
font-size: 14px !important;
font-style: normal !important;
font-weight: 500 !important;
line-height: normal !important;
}
.userDiv .updatetime{
color: #A9A9A9 !important;
font-size: 14px !important;
font-weight: 400 !important;
line-height: 14px !important;
padding-top: 5px !important;
}
@media screen and (min-width: 480px) {
    .userDiv .updatetime{
        padding-top: 10px !important;
    }
}
.userDiv .userEllipsis{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #121016 !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 20px !important; 
}
@media screen and (min-width: 480px) {
    .userDiv .userEllipsis{
        font-size: 16px !important;
    }
}
.userimgSearch {
    height: 50px !important;
    width: 50px !important;
    border-radius: 50% !important;
}
.userimgSearch img{
    width: 100% !important;
    height: 100% !important;
    border-radius: 50% !important;
    object-fit: cover !important;
}


.followBtn{
    border-radius: 23px !important;
    height: 100% !important;
    padding: 3px 15px !important;
    line-height: normal !important;
    width: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    background-size: cover !important;
    background-position: center !important;

}
.careerBtn{
    color: #FFF !important;
    font-size: 13px !important;
    font-style: italic !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    border-radius: 10px !important;
    padding: 7px 28px !important;
    background-color: #EF5036 !important;
    border: none !important;
    margin-top: 15px;
 }

.prevBtnSearch{
    color: #FFF !important;
    font-size: 13px !important;
    font-style: italic !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    border-radius: 10px !important;
    padding: 7px 28px !important;
    background-color: #241840 !important;
    border: none !important;
    margin-top: 15px;
}

.notifiDrop{
    margin-top: 25px !important;
    background-color: white !important;
    box-shadow: 0px 4px 136px -11px rgba(0,0,0,.08) !important;
}

@media  (max-width:567px) {
    .userimgSearch {
        height: 40px !important;
        width: 40px !important;
    }
}
  