.react-calendar{
    border: none !important;
}

.card_box_sh .clacactive{
    position: absolute !important;
    z-index: 100 !important;
    left: 58% !important;
    margin-top: 8px !important;
    background-color: #fde5e1 !important;
    border-radius: 30px !important;
    width: 98px !important;
    height: 30px !important;
    color: #EF5036 !important;
    padding: 4px 12px !important;
    font-size: 15px;
    margin-left: 34px !important;
}
.MainCardCalendar{
   font-size: 14px !important;
}
.lineHeightMain{
    line-height: 19.8px !important;
}
.lineuserGender{
    line-height: 15.4px !important;
}
.saveBtnMain{
    padding: 9px 67px !important;
}
.fontReferMain{
    font-size: 32px !important;
    line-height: 38px !important;
}
.subHeadingMain{
    font-size: 16px !important;
    line-height: 20px !important;
}
.inviteBtnChange{
    padding: 15px 40px !important;
}


/* .btn-check:checked+.btn, :not(.btn-check)+.btn:active, .btn:first-child:active, .btn.active, .btn.show {
    color: white !important;

} */


.profileMyLeaderBoard .rightSection #changePassword .changePassword .password .form-control {
    padding: 15px 16px !important;
}
.silverPlan {
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.01) !important;
    border: none ;

}

.btn-theme2.textBtn{
    padding: 10px 58px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}

#addnewaddress .modal-body .addressFilds .form-control {
    padding: 14px 16px;
    font-size: 14px;
    border-color: #e4e5e9;
    border-radius: 10px;
}

@media  (max-width:991px) {
    .react-calendar__navigation button {
        font-size: 18px !important;
        font-weight: bold !important;
    }
}

@media  (max-width:567px) {
    .react-calendar__navigation button {
        font-size: 14px !important;
        font-weight: bold !important;
    }
    .profileMyLeaderBoard .rightSection #changePassword .changePassword .password .form-control {
        padding: 7px 20px !important;
    }
    .fontReferMain {
        font-size: 27px !important;
        line-height: 33px !important;
    }
}

@media  (max-width:1191px) {
    .card_box_sh .clacactive{
        position: absolute !important;
        z-index: 100 !important;
        left: 36% !important;
        margin-top: 32px !important;
        background-color: #fde5e1 !important;
        border-radius: 30px !important;
        width: 80px !important;
        height: 22px !important;
        color: #EF5036 !important;
        padding: 3px 10px !important;
        font-size: 12px !important;
        margin-left: 37px !important;
    }
}

@media  (max-width:567px) {
    .card_box_sh .clacactive{
 
        left: 27% !important;
  
    }
}